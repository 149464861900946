// 引入抽屉详情组件
import RoleAuthorizationDrawer from "./components/roleAuthorizationDrawer/index.vue";
// 引入api
import { fun_PostAction } from "@/api";
// 引入公共方法
import { fun_GetKeys, fun_FixedAuthority } from "@/utils/validate";
// 默认导出
export default {
  // 数据存储对象
  data: () => {
    return {
      // 列表loading初始状态
      loading: false,
      // 查询表单字段
      form: {
        name: null // 角色名称
      },
      // 查询表单
      formSearchList: [
        {
          type: "input",
          label: "角色名称：",
          value: "name",
          placeholder: "请输入"
        }
      ],
      // 表头按钮组
      btnList: [
        {
          name: "添加",
          type: "success",
          icon: "document-add"
        },
        {
          name: "修改",
          type: "primary",
          icon: "edit"
        },
        {
          name: "删除",
          type: "danger",
          icon: "delete"
        }
      ],
      // 表格基础数据
      tableColumns: [
        {
          label: "角色名称",
          prop: "name"
        },
        {
          label: "角色描述",
          prop: "roleDescribe"
        },
        {
          label: "是否固定角色",
          prop: "fixedAuthority"
        }
      ],
      // 表格数据
      tableData: [],
      // 分页
      reqPageData: {
        current: 1, // 页码
        size: 10 // 每页条数
      },
      total: 0, // 分页总条数
      // 勾选的数据
      multipleSelection: []
    };
  },

  // 注册组件
  components: {
    // 抽屉详情组件
    RoleAuthorizationDrawer
  },

  // 模板编译完成
  mounted() {
    // 获取一览表格数据
    this.fun_GetList();
  },

  // 自定义方法
  methods: {
    /**
     * 获取一览表格数据
     * @author 滕威
     */
    async fun_GetList() {
      // 打开loading
      this.loading = true;
      // 请求，获取结果集
      let res = await fun_PostAction("/sysPower/list", {
        ...this.form,
        ...this.reqPageData
      });
      // 解构res.data
      const { result, data, msg } = res.data;
      // 判断请求是否成功
      if (result) {
        // 解构data
        const { total, records } = data;
        // 遍历records
        records.map(e => {
          // 转译是否固定角色
          e.fixedAuthority = fun_FixedAuthority(e.fixedAuthority);
        });
        // 回显table
        this.tableData = records;
        // 获取分页总条数
        this.total = total;
      } else {
        // 提示请求失败消息
        this.$message.error(msg);
      }
      // 关闭loading
      this.loading = false;
    },
    /**
     * 点击修改按钮
     * @author 滕威
     */
    fun_Edit() {
      // 判断是否勾选
      if (this.multipleSelection && this.multipleSelection.length > 0) {
        // 勾选超过1条
        if (this.multipleSelection.length > 1) {
          // 提示，并清除选中数据
          this.$alert("只能选择一条数据！", "warning").then(() => {
            // 清除选中数据
            this.$refs.list.$refs.multipleTable.clearSelection();
          });
        } else {
          // 打开抽屉
          this.$refs.roleAuthorizationDrawer.drawerStatus = true;
          // 获取id
          const id = this.multipleSelection[0].id;
          // 调用弹窗获取当前角色拥有菜单方法
          this.$refs.roleAuthorizationDrawer.fun_GetPowerOperation(id);
          // 调用弹窗获取详情方法
          this.$refs.roleAuthorizationDrawer.fun_GetDetails(id);
        }
      } else {
        // 未勾选，则提示
        this.$alert("请选择一条数据！", "warning");
      }
    },
    /**
     * 点击删除按钮
     * @author 滕威
     */
    fun_Del() {
      // 判断是否勾选
      if (this.multipleSelection && this.multipleSelection.length > 0) {
        // 弹出询问confirm
        this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(() => {
          // 获取keys
          const keys = fun_GetKeys(this.multipleSelection);
          // 发起请求，获取结果集
          fun_PostAction("/sysPower/deletePower?keys=" + keys).then(res => {
            // 解构res.data
            const { result, msg } = res.data;
            // 判断请求是否成功
            if (result) {
              // 查询列表
              this.fun_GetList();
              // 提示删除成功
              this.$message.success(msg);
            } else {
              // 请求失败，提示信息
              this.$message.error(msg);
            }
          });
        });
      } else {
        // 未勾选，则提示
        this.$alert("请选择一条数据！", "warning");
      }
    },
    /**
     * 查询
     * @author 滕威
     */
    fun_Search() {
      // 获取一览表格数据
      this.fun_GetList();
    },
    /**
     * 改变每页条数执行的函数
     * @params e 每页条数
     * @author 滕威
     */
    fun_LimitChange(e) {
      // 获取每页显示条数
      this.reqPageData.size = e;
      // 获取一览表格数据
      this.fun_GetList();
    },
    /**
     * 改变页码执行的函数
     * @params e 页码数
     * @author 滕威
     */
    fun_PageChange(e) {
      // 获取当前页码
      this.reqPageData.current = e;
      // 获取一览表格数据
      this.fun_GetList();
    },
    /**
     * 表头按钮事件
     * @params e 按钮name
     * @author 滕威
     */
    fun_click(e) {
      switch (e) {
        case "添加":
          // 打开抽屉
          this.$refs.roleAuthorizationDrawer.drawerStatus = true;
          // 添加时，清空id
          this.$refs.roleAuthorizationDrawer.form.id = null;
          break;
        case "修改":
          // 调用修改方法
          this.fun_Edit();
          break;
        default:
          // 调用删除方法
          this.fun_Del();
      }
    },
    /**
     * 勾选事件
     * @params e 选中的数据
     * @author 滕威
     */
    fun_HandleSelectionChange(e) {
      // 将勾选的value值赋给multipleSelection数组
      this.multipleSelection = e;
    }
  }
};
