// 引入api
import { fun_PostAction } from "@/api";
// 默认导出
export default {
  // 数据存储对象
  data: () => {
    return {
      // 抽屉状态
      drawerStatus: false,
      // 抽屉确定按钮loading状态
      loading: false,
      // 查询表单字段
      form: {
        id: null, // id
        name: null, // 角色名称
        fixedAuthority: null, // 是否固定角色
        roleDescribe: null, // 角色描述
        cdbh: "" // 菜单编号
      },
      // 详情表单基础数据
      formConfigList: [
        {
          label: "角色名称：",
          value: "name",
          type: "input",
          required: true,
          rule: {
            required: true,
            message: "请输入角色名称",
            trigger: "blur"
          }
        },
        {
          label: "是否固定角色：",
          value: "fixedAuthority",
          type: "select",
          required: true,
          rule: {
            required: true,
            message: "请选择",
            trigger: "change"
          },
          queryParams: {
            sqlId: "201907041546460002",
            dictionaryId: "ca7aa4c6-d33c-4b4d-9ba7-b06dc67e46b9"
          }
        },
        {
          label: "角色描述：",
          value: "roleDescribe",
          type: "input",
          required: true,
          rule: {
            required: true,
            message: "请输入角色描述",
            trigger: "blur"
          }
        }
      ],
      // 后台返回错误对象
      errRules: {},
      // tree数据
      data: [],
      // tree数据默认参数和后台给的参数对应
      defaultProps: {
        children: "children", // 子节点名称
        label: "title" // 节点名称
      },
      currentKey: null, // 选中
      checkedList: [] // 默认选中的数组
    };
  },

  // 自定义方法
  methods: {
    /**
     * 获取详情
     * @author 滕威
     */
    async fun_GetDetails(id) {
      this.form.id = id;
      // 请求，获取结果集
      let res = await fun_PostAction("/sysPower/powerDetail?id=" + id);
      // 解构res.data
      const { result, data, msg } = res.data;
      // 判断请求是否成功
      if (result) {
        // 回显表单
        this.form = data;
      } else {
        // 请求失败，提示错误信息
        this.$message.error(msg);
      }
    },
    /**
     * 获取所有权限树
     * @author 滕威
     */
    async fun_GetAllTreeData() {
      // 发起请求，获取结果集
      let res = await fun_PostAction("/sysOperation/operationListInit");
      // 解构res.data
      const { result, data, msg } = res.data;
      // 判断请求是否成功
      // 成功
      if (result) {
        // 回显菜单权限tree
        this.data = data;
      } else {
        // 失败
        // 提示信息
        this.$message.error(msg);
      }
    },
    /**
     * 查询当前角色所拥有的菜单
     * @params id 入参id
     * @author 滕威
     */
    async fun_GetPowerOperation(id) {
      // 发起请求，获取结果集
      let res = await fun_PostAction(
        "/sysOperation/powerOperationDetail?id=" + id
      );
      // 判断请求是否成功
      if (res.data.result) {
        // 成功则定义空数组
        let arr = [];
        // 遍历解析得到的数组
        res.data.data.forEach(item => {
          // 将遍历出来的值push到arr数组中
          arr.push(item.operationId);
        });
        // 将arr赋值给勾选数组
        this.checkedList = arr;
      }
    },
    /**
     * 表单提交
     * @author 滕威
     */
    fun_SubmitForm() {
      // 校验
      this.$refs.form.$refs.enInform.validate(valid => {
        if (valid) {
          // 将菜单编号置空
          this.form.cdbh = "";
          // 调用fun_GetCheckedKeys方法，获取勾选菜单id
          let aKey = this.fun_GetCheckedKeys(
            this.data,
            this.$refs.tree.getCheckedKeys(),
            "id"
          );
          console.log(aKey);
          // 获取菜单编号
          this.fun_GetCdbh(aKey);
          // 发起请求，获取结果集
          fun_PostAction("/sysPower/powerSaveOrUpdate", this.form).then(res => {
            // 解构res.data
            const { result, msg } = res.data;
            // 判断请求是否成功
            if (result) {
              // 请求一览查询接口
              this.$parent.fun_GetList();
              // 关闭弹窗
              this.drawerStatus = false;
              // 提示信息
              this.$message.success(msg);
            } else {
              // 请求失败，提示信息
              this.$message.error(msg);
            }
          });
        } else {
          // 校验不通过，返回false，页面给出前端提示
          return false;
        }
      });
      // 关闭抽屉
    },
    /**
     * 抽屉打开时执行
     * @author 滕威
     */
    fun_OpenDrawer() {
      // 获取所有权限树
      this.fun_GetAllTreeData();
    },
    /**
     * 抽屉关闭时执行
     * @author 滕威
     */
    fun_DrawerClose() {
      // 清空是否固定角色下拉框
      this.$refs.form.$refs.formItem[1].$children[1].value = null;
      // 清除表单验证和数据
      this.$refs.form.$refs.enInform.resetFields();
      // 关闭时，清除勾选
      this.$parent.$refs.list.$refs.multipleTable.clearSelection();
      // 清空tree选中的节点
      this.$refs.tree.setCheckedKeys([]);
    },
    /**
     * 根据子节点来获取父节点
     * @params data 树形总数据
     * @params keys getCheckedKeys获取到的选中的key值
     * @params key
     * @author 滕威
     */
    fun_GetCheckedKeys(data, keys, key) {
      // 定义空数组
      let res = [];
      // 调用fun_Recursion方法
      fun_Recursion(data, false);
      return res;
      /**
       * 根据子节点来获取父节点
       * @params arr 树形总数据
       * @params isChild 是否是子节点
       * @author 滕威
       */
      function fun_Recursion(arr, isChild) {
        // 定义空数组
        let aCheck = [];
        // 遍历arr数组参数
        for (let i = 0; i < arr.length; i++) {
          // 取到遍历后的每个值
          let obj = arr[i];
          // 设置aCheck数组每个值都为false
          aCheck[i] = false;
          // 判断obj是否有children子级
          if (obj.children) {
            // 如果有，则递归调用
            // 判断aCheck[i]的状态
            aCheck[i] = fun_Recursion(obj.children, true) ? true : aCheck[i];
            // 如果aCheck[i]为true
            if (aCheck[i]) {
              res.push(obj[key]);
            }
          }
          // 遍历选中的节点数组
          for (let j = 0; j < keys.length; j++) {
            // 判断obj[key]、keys[j]值是否相等
            if (obj[key] == keys[j]) {
              // 相等则aCheck[i]为true
              aCheck[i] = true;
              // 判断res数组是否包含obj[key]
              if (res.indexOf(obj[key]) == -1) {
                // 包含则将自身push到res中
                res.push(obj[key]);
              }
              break;
            }
          }
        }
        // 如果是子节点则返回
        if (isChild) {
          return aCheck.indexOf(true) != -1;
        }
      }
    },
    /**
     * 下拉框选择事件
     * @param {e} 选择的value值
     * @author 滕威
     */
    fun_GetSelectVal(e) {
      // 回显
      this.form.fixedAuthority = e;
    },
    /**
     * 获取菜单编号
     * @param {aKey} 勾选的菜单id数组
     * @author 滕威
     */
    fun_GetCdbh(aKey) {
      aKey.map(e => {
        // 判断cdbh是否为空
        if (this.form.cdbh == "") {
          // 为空则赋值
          this.form.cdbh = e;
        } else {
          // 有值则拼接
          this.form.cdbh += ";" + e;
        }
      });
    }
  }
};
